import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Checkbox from 'src/components/Checkbox/Checkbox.component';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProps } from 'src/pages/registration/Registration.types';
import {
  flagB,
  flagC,
} from 'src/pages/registration/partials/FormFlag/FormFlag.config';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  guidField,
  guidOrderField,
  ipAddressField,
} from 'src/services/fido/fido.config';
import { fidoMethodApi } from 'src/services/fido/fido.request';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { performSignUpSessionMethodApi } from 'src/services/registration/registration.request';
import { typeBuyerGuidKey } from 'src/services/session/session.config';
import { useSession } from 'src/services/session/session.context';
import { buyerGetInfo, userEndPoint } from 'src/services/user/user.config';
import {
  buyerGetInfoMethodApi,
  porfileMethodApi,
} from 'src/services/user/user.request';
import {
  useAccountStore,
  useCurrentTransactionStore,
  useProfileStore,
  useRegistrationStore,
} from 'src/store/store';
import colors from 'src/style-utils/colors';
import { P, PNote } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { FormStyle } from 'src/style/styleInput.style';
import { getPublicIp } from 'src/utils/functions/getPublicIp';
import {
  flagCreditAssignment,
  formConfigFlagStepRegistration,
} from './FormFlagToPay.helpers';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const FormFlagToPay: React.FC<FormProps> = ({
  step,
  stepMax,
  light,
  nextPath,
  label,
}) => {
  const buyerGuid = useProfileStore((state) => state.currentOrder?.BuyerGuid);
  const guidOrder = useProfileStore((state) => state.currentOrder?.OrderGuid);
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const setCurrentOrder = useProfileStore((state) => state.setCurrentOrder);
  const setUser = useProfileStore((state) => state.setUser);
  const setValue = useProfileStore((state) => state.setValue);
  const setAccount = useAccountStore((state) => state.setAccount);
  const sessionGuid = useRegistrationStore((state) => state.sessionGuid);
  const { language } = useLanguage();
  const { onLogin } = useSession();
  const [ipAddress, setIpAddress] = useState<string>('');
  useEffect(() => {
    getPublicIp().then((res) => setIpAddress(res));
  }, []);
  const { initialValues, resolver } = formConfigFlagStepRegistration();
  const lender = useCurrentTransactionStore((state) => state.lender);
  const navigate = useNavigate();
  const {
    mutate: mutateFido,
    isPending: isLoadingFido,
    isSuccess,
    isError,
    data: dataFido,
  } = useMutation({ mutationFn: fidoMethodApi });
  if (isSuccess) {
    navigate(dataFido?.data.ResultSet ? nextPath : '');
  }
  if (isError) {
    navigate(RoutePath.ThankyouPageError + RoutePath.loginBuyerError);
  }

  const {
    mutate: mutatePerform,
    isPending: isLoadingPerform,
    isSuccess: isSuccessPerform,
  } = useMutation({
    mutationFn: performSignUpSessionMethodApi,
    onSuccess: (res) => {
      onLogin(res.data.ResultSet.AccessToken);
      if (!light) {
        setCurrentOrder({
          ...currentOrder,
          BuyerRegistrationStatus: 'registered',
        });
      }
      const data = {
        [guidField]: buyerGuid,
        [guidOrderField]: guidOrder,
        [ipAddressField]: ipAddress,
      };
      mutateFido({ ...data });
    },
    onError: () =>
      navigate(RoutePath.ThankyouPageError + RoutePath.loginBuyerError),
  });

  const { isSuccess: isSuccessAccountInfo, data } = useQuery({
    queryKey: [userEndPoint],
    queryFn: async () => {
      const response = await porfileMethodApi();
      setAccount(response?.data.ResultSet);
      window.localStorage.setItem(
        typeBuyerGuidKey,
        response?.data.ResultSet.BuyerRoles[0].Guid as string,
      );

      return response;
    },
    enabled: isSuccessPerform,
  });

  useQuery({
    queryKey: [buyerGetInfo],
    queryFn: async () => {
      const response = await buyerGetInfoMethodApi();
      setValue('AccountType', 'BUYER');
      setUser(response?.data.ResultSet);
      return response;
    },
    enabled:
      isSuccessAccountInfo && data?.data?.ResultSet.BuyerRoles?.length > 0,
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    const performData = {
      FlagPrivacy: true,
      FlagB: data[flagB],
      FlagC: data[flagC],
      SessionGuid: sessionGuid,
      Language: language,
    };

    mutatePerform(performData);
  };

  if (isLoadingFido || isLoadingPerform)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        label="lbl.flagOpynPayLater"
        showWhiteSpaceRight
        stepNumber={step}
        stepMaxNumber={stepMax}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <div className="content">
          <FormProvider {...methods}>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              <PNote lineHeight="1.6rem" textColor={colors.textColorGrey}>
                <Translate id="lbl.obbligatoryStrong" />
              </PNote>
              <Flex
                style={{
                  borderBottom: !light ? '1px solid #C9CDD6' : undefined,
                }}
                marginBottom="2.4rem"
                paddingBottom="2.4rem"
                justify="space-between"
                align="center"
                flexWrap="nowrap"
              >
                <P marginRight="2rem" marginTop="0">
                  <Translate
                    id={'text.okCreditAssignment'}
                    data={{ x: lender }}
                  />
                </P>
                <Checkbox viewMode="inline" name={flagCreditAssignment} />
              </Flex>
              {!light && (
                <>
                  <Flex
                    paddingBottom="2.4rem"
                    justify="space-between"
                    align="center"
                    flexWrap="nowrap"
                  >
                    <P marginRight="2rem">
                      <Translate id={'text.marketingChooseDirect'} />
                    </P>
                    <Checkbox viewMode="inline" name={flagB} />
                  </Flex>
                  <Flex
                    paddingBottom="2.4rem"
                    justify="space-between"
                    align="center"
                    flexWrap="nowrap"
                  >
                    <P marginRight="2rem">
                      <Translate id={'text.marketingChooseCommercial'} />
                    </P>
                    <Checkbox viewMode="inline" name={flagC} />
                  </Flex>
                </>
              )}
              <ButtonContainer>
                <SubmitButton
                  minWidth="100%"
                  disabled={isLoadingPerform || isLoadingFido}
                >
                  <Translate id={label ?? 'lbl.registration'} />
                </SubmitButton>
              </ButtonContainer>
            </FormStyle>
          </FormProvider>
        </div>
      </FlowContainer>
    </StyleSheetManager>
  );
};

export default FormFlagToPay;
