import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Checkbox from 'src/components/Checkbox/Checkbox.component';
import { GenericErrorForm } from 'src/components/GenericErrorForm/GenericErrorForm.component';
import InputField from 'src/components/InputField/InputField.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { signInForPluginCheckoutMethodApi } from 'src/services/ecommerce/ecommerce.request';
import { SignInForPluginCheckoutData } from 'src/services/ecommerce/ecommerce.types';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { SupportedLanguageKey } from 'src/services/i18n/i18n.types';
import {
  buyerGuid,
  emailField,
  passwordField,
  rememberMe,
} from 'src/services/login/login.config';
import {
  LoginMethodApi,
  PreLoginMethodApi,
} from 'src/services/login/login.request';
import { useSession } from 'src/services/session/session.context';
import {
  buyerGetInfo,
  merchantGetInfo,
  userEndPoint,
} from 'src/services/user/user.config';
import {
  buyerGetInfoMethodApi,
  merchantGetInfoMethodApi,
  porfileMethodApi,
} from 'src/services/user/user.request';
import { useAccountStore, useProfileStore } from 'src/store/store';
import { Flex } from 'src/style/flexbox.style';
import { FormStyle } from 'src/style/styleInput.style';
import { getCookie } from 'src/utils/functions/cookie';
import servicesErrorsMap from 'src/utils/functions/servicesErrorsMap';
import { setRememberCookie } from './FormLogin.config';
import { formConfig } from './FormLogin.helpers';
import { LinkRecovery } from './style/FormLogin.style';
import {
  typeBuyerGuidKey,
  typeMerchantGuidKey,
} from 'src/services/session/session.config';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';
import { navigationStatusMappings } from 'src/utils/config/stateAccount';

export type FormLoginProps = {
  plugin?: boolean;
  sessionGuid?: string;
};

function FormLogin({ plugin, sessionGuid }: FormLoginProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { onLogin } = useSession();
  const setUser: any = useProfileStore((state) => state.setUser);
  const setAccount = useAccountStore((state) => state.setAccount);
  const setValue = useProfileStore((state) => state.setValue);
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const activeOrder = location.state?.activeOrder;
  const [errorMessage, setErrorMessage] = useState('');
  const [typeGetInfo, setTypeGetInfo] = useState('');
  const rememberCookie = getCookie(rememberMe);
  const rememberData: { email: string; flag: boolean } =
    rememberCookie && JSON.parse(rememberCookie);
  const { initialValues, resolver } = formConfig(rememberData);
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  useEffect(() => {
    setValue('AccountType', ''); // FOR CUSTOM BUYER LANGUAGE
  }, []);

  const {
    language,
    setLanguage,
    isLoadingLang,
    isLoadingUpLang,
    mutateUpLang,
  } = useLanguage();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: LoginMethodApi,
    onSuccess: async (res, variables) => {
      setRememberCookie(
        rememberMe,
        variables[rememberMe],
        variables[emailField],
      );
      const { AccessToken } = res?.data?.ResultSet;
      onLogin(AccessToken);
    },
    onError: (res: any) => {
      const errors = servicesErrorsMap(res.response, { email: emailField });
      for (const key in errors) {
        if (key === 'generic') setErrorMessage(errors[key]);
        else methods.setError(key, { message: errors[key] });
      }
    },
  });
  const {
    isLoading: isLoadingInfo,
    isSuccess: isSuccessAccountInfo,
    data: dataAccountInfo,
  } = useQuery({
    queryKey: [userEndPoint],
    queryFn: async () => {
      const response = await porfileMethodApi();
      setAccount(response.data.ResultSet);
      if (response.data.ResultSet?.MerchantRoles?.length > 0) {
        window.localStorage.setItem(
          typeMerchantGuidKey,
          response.data.ResultSet.MerchantRoles[0].Guid,
        );
        setTypeGetInfo('MERCHANT');
      } else if (response.data.ResultSet?.BuyerRoles?.length > 0) {
        window.localStorage.setItem(
          typeBuyerGuidKey,
          response?.data.ResultSet.BuyerRoles[0].Guid,
        );
        setTypeGetInfo('BUYER');
      }
      return response;
    },
    enabled: isSuccess,
  });

  const { isLoading: isLoadingMerchantInfo } = useQuery({
    queryKey: [merchantGetInfo],
    queryFn: async () => {
      const response = await merchantGetInfoMethodApi();
      setUser(response?.data.ResultSet);
      setValue('AccountType', 'MERCHANT');
      setValue('Role', dataAccountInfo?.data.ResultSet.MerchantRoles[0].Role);
      if (dataAccountInfo?.data.ResultSet.Language === '') {
        mutateUpLang({ Language: language });
        return;
      }
      if (language !== dataAccountInfo?.data.ResultSet.Language) {
        setLanguage(
          dataAccountInfo?.data.ResultSet.Language as SupportedLanguageKey,
        );
      }
      const AccountStatus = response?.data.ResultSet.Status;
      if (AccountStatus && AccountStatus !== 1) {
        const navigationPath =
          navigationStatusMappings[AccountStatus]?.['MERCHANT'];
        if (navigationPath) return navigate(navigationPath);
      }
      navigate(`${RoutePath.privateArea}?typeAccount=MERCHANT`);
      return response;
    },
    enabled: isSuccessAccountInfo && typeGetInfo === 'MERCHANT',
  });

  const { isLoading: isLoadingBuyerInfo } = useQuery({
    queryKey: [buyerGetInfo],
    queryFn: async () => {
      const response = await buyerGetInfoMethodApi();
      setValue('AccountType', 'BUYER');
      setValue('Role', dataAccountInfo?.data.ResultSet.BuyerRoles[0].Role);
      if (dataAccountInfo?.data.ResultSet.Language === '') {
        mutateUpLang({ Language: language });
        return;
      }
      if (language !== dataAccountInfo?.data.ResultSet.Language) {
        setLanguage(
          dataAccountInfo?.data.ResultSet.Language as SupportedLanguageKey,
        );
      }
      setUser(response?.data.ResultSet);
      navigate(`${RoutePath.privateArea}?typeAccount=BUYER`);
      return response;
    },
    enabled: isSuccessAccountInfo && typeGetInfo === 'BUYER',
  });

  const { mutate: mutatePlugin, isPending: isLoadingPlugin } = useMutation({
    mutationFn: (values: SignInForPluginCheckoutData) =>
      signInForPluginCheckoutMethodApi(values),
    onSuccess: async (res) => {
      onLogin(res.data.ResultSet.AccessToken);
    },
    onError: (res: any) => {
      const errors = servicesErrorsMap(res.response, { email: emailField });
      for (const key in errors) {
        if (key === 'generic') setErrorMessage(errors[key]);
        else methods.setError(key, { message: errors[key] });
      }
    },
  });
  const { mutate: mutatePreLogin, isPending: isLoadingPreLogin } = useMutation({
    mutationFn: PreLoginMethodApi,
    onSuccess: (res, variables) => {
      setRememberCookie(
        rememberMe,
        variables[rememberMe],
        variables[emailField],
      );
      navigate(RoutePath.welcomePage, {
        state: {
          login: 'login',
          email: variables[emailField],
          password: variables[passwordField],
        },
      });
    },
    onError: (res: any) => {
      const errors = servicesErrorsMap(res.response, { email: emailField });
      for (const key in errors) {
        if (key === 'generic') setErrorMessage(errors[key]);
        else methods.setError(key, { message: errors[key] });
      }
    },
  });

  const onSubmit = async (data: any) => {
    if (activeOrder) {
      data[buyerGuid] = currentOrder?.BuyerGuid;
      mutatePreLogin(data);
      return;
    }
    plugin
      ? mutatePlugin({
          Email: data[emailField],
          Password: data[passwordField],
          SessionGuid: sessionGuid,
        })
      : mutate(data);
  };

  if (
    isPending ||
    isLoadingPreLogin ||
    isLoadingPlugin ||
    isLoadingInfo ||
    isLoadingLang ||
    isLoadingUpLang ||
    isLoadingMerchantInfo ||
    isLoadingBuyerInfo
  ) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FormProvider data-component="form-login" {...methods}>
        <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <InputField
              isPresentMessage
              label="lbl.emailAddress"
              name={emailField}
              id="InputEmail"
            />
          </div>
          <div>
            <InputField
              type="password"
              name={passwordField}
              label="lbl.password"
              id="InputPassword"
            />
          </div>
          <div>
            <Checkbox
              i18n
              paddingTop="1.6rem"
              fontWeight="normal"
              label="lbl.rememberMe"
              squareCheckBox
              viewMode="inline"
              defaultChecked={true}
              name={rememberMe}
            />
          </div>

          <ButtonContainer>
            <Button
              type="submit"
              minWidth="100%"
              sizeOnMobile="medium"
              sizeOnDesktop="medium"
              variant="Primary"
              disabled={isPending || isLoadingPreLogin}
              id="BtnLogin"
            >
              <Translate id="lbl.login" />
            </Button>
          </ButtonContainer>
          <Flex
            marginTop="4rem"
            gap="2.4rem"
            flexDirection="column"
            marginBottom={plugin ? '6.4rem' : ''}
          >
            <LinkRecovery
              to={{
                pathname: RoutePath.recoveryData + RoutePath.recoveryEmail,
              }}
              state={{ activeOrder }}
            >
              <br />
              <Translate
                id={'link.forgottenEmail'}
                extraTranslationProps={{
                  components: { u: <u /> },
                }}
              />
            </LinkRecovery>

            <LinkRecovery
              to={{
                pathname: RoutePath.recoveryData + RoutePath.recoveryPassword,
              }}
              state={{ activeOrder }}
            >
              <Translate
                id="link.forgottenPassword"
                extraTranslationProps={{
                  components: { u: <u /> },
                }}
              />
            </LinkRecovery>
          </Flex>
          {errorMessage && (
            <GenericErrorForm isPresentMessage errorMessageID={errorMessage} />
          )}
        </FormStyle>
      </FormProvider>
    </StyleSheetManager>
  );
}

export default FormLogin;
