import React from 'react';
import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import { useNavigate } from 'react-router-dom';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as editedMail } from 'src/images/pageStatus/created_link_success.svg';
import { H4 } from 'src/style-utils/typographic';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { useAccountStore } from 'src/store/store';
import { userEndPoint } from 'src/services/user/user.config';
import { porfileMethodApi } from 'src/services/user/user.request';
import { useQuery } from '@tanstack/react-query';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const SuccessChangeEmail: React.FC = () => {
  const navigate = useNavigate();
  const setAccount = useAccountStore((state) => state.setAccount);
  useQuery({
    queryKey: [userEndPoint],
    queryFn: async () => {
      const response = await porfileMethodApi();
      setAccount(response?.data.ResultSet);
      return response;
    },
  });
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon
        svgIconComponent={editedMail}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 marginBottom="10rem" textAlign="center">
        <Translate id="text.modifiedEmail" />
      </H4>
      <ButtonContainer>
        <Button minWidth="100%" i18n onClick={() => navigate('/access-data')}>
          <Translate id="link.backToAccessData" />
        </Button>
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default SuccessChangeEmail;
