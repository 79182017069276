import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Card, ResponsiveContainer } from 'src/style/Container.style';
import styled from 'styled-components';

export const CardLinkContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: unset;
  position: relative;

  > hr {
    width: 100%;
    border-color: ${colors.greyBorder};
  }

  > h5 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &[data-selected='true'] {
    outline: ${colors.greyStatus} 0.15rem solid;
  }
`;

export const CardLinkGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  padding: 2.4rem 0;
  margin: 0 auto auto;
  gap: 8px;

  > div {
    background: #f5faff;
    border-radius: 0.8rem;
    padding: 0.8rem;
  }

  > div:last-child {
    grid-column: span 2;
  }
`;

export const CardGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 32.6rem), 1fr));
  grid-auto-rows: 1fr;
  gap: 2.4rem;
`;

export const CustomButton = styled(Button)`
  &[data-selected='true'] {
    background-color: ${colors.backgroundGreen};
    outline: ${colors.borderbgBtnQuaternary} 0.2rem solid;
  }
`;

export const CustomIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-25%) translateX(25%);
`;

export const ButtonContainerCustom = styled.div`
  position: fixed;
  width: 100%;
  gap: 1.6rem;
  bottom: 0;
  z-index: 5;
  background: ${colors.backgroundGrey};
  padding: 2.4rem;
  box-shadow: -4px 4px 24px 0.4px ${colors.modalFooterShadowColor};
  ${media.tablet`
    flex-direction: row;
    width: calc(100% - 200px);
    max-width: 1240px;
  `};
`;

export const ResponsiveContainerCustom = styled(ResponsiveContainer)`
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2.4rem;
  display: flex;

  > button {
    width: 100%;
  }

  ${media.tablet`
    flex-direction: row;
    > button{
      width: 100%;
      flex-basis: 28rem;
    }
  `}
`;
