import Loader from 'src/components/Loader/Loader.component';
import React from 'react';
import GoBackButton from '../../components/GoBackButton/GoBackButton.component';
import { H4, P, PSmall } from '../../style-utils/typographic';
import Translate from '../../components/Translate/Translate.component';
import { ResponsiveContainer } from '../../style/Container.style';
import { Flex } from '../../style/flexbox.style';
import { Button } from '../../components/Button/Button.component';
import { ButtonContainer } from '../../components/Button/style/Button.style';
import { FundTrasferCardContainer } from './FundTransfer.style';
import { useI18n } from '../../services/i18n/i18n.hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { balanceAndMovementsInitialDataEndPoint } from '../../services/balanceAndMovementsInitialData/balanceAndMovementsInitialData.config';
import {
  createPayoutDataMethodApi,
  getBalanceAndMovementsInitialDataMethodApi,
} from '../../services/balanceAndMovementsInitialData/balanceAndMovementsInitialData.request';
import { RoutePath } from '../../routers/routers.config';
import { useNavigate } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export interface BalanceAndMovementsModel {
  LastTransactionDate: string;
  BankAccountDetails: string;
  NextPayoutDate: string;
  StripeBalance: number;
  PendingBalance: number;
}

const FundTransfer: React.FC = () => {
  const navigate = useNavigate();
  const [balanceAndMovements, setBalanceAndMovements] =
    React.useState<BalanceAndMovementsModel | null>(null);
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const { isFetching } = useQuery({
    queryKey: [balanceAndMovementsInitialDataEndPoint],
    queryFn: async () => {
      try {
        const response = await getBalanceAndMovementsInitialDataMethodApi();
        setBalanceAndMovements(response?.data.ResultSet);
        return response;
      } catch {
        navigate(RoutePath.balanceMovements);
      }
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: createPayoutDataMethodApi,
    onSuccess: () => {
      navigate(RoutePath.balanceMovements, {
        state: {
          showToastCreatePayout: true,
        },
      });
    },
    onError: () => navigate(RoutePath.oops),
  });

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {isFetching || isPending ? (
        <Loader overlayViewMode="fullscreen" active={true} viewMode="fluid" />
      ) : null}

      {balanceAndMovements && (
        <>
          <ResponsiveContainer>
            <GoBackButton />
            <div className="mx-auto my-6 max-w-oxl">
              <Flex
                flexDirection="column"
                gap="0.8rem"
                className="lg:max-w-olg lg:text-center"
                margin="0 auto"
              >
                <H4>
                  <Translate id={'text.fundTransfer.title'} />
                </H4>
              </Flex>
              <FundTrasferCardContainer>
                <Flex
                  className="w-full"
                  flexDirection="column"
                  justify="center"
                  gap="0.8rem"
                  padding="2.4rem"
                >
                  <PSmall>
                    <Translate id={'text.fundTransfer.amountToTransfer'} />
                  </PSmall>
                  <P bold textColor="inherit">
                    {formatCurrency(balanceAndMovements?.StripeBalance)}
                  </P>
                  <hr
                    className="w-full border-b border-pgreyoutlined"
                    style={{ margin: '0.8rem 0' }}
                  />
                  <PSmall>
                    <Translate id={'text.fundTransfer.corporateAccount'} />
                  </PSmall>
                  <P bold textColor="inherit">
                    {balanceAndMovements?.BankAccountDetails}
                  </P>
                </Flex>
              </FundTrasferCardContainer>
              <ButtonContainer>
                <Button
                  i18n
                  translatedText="lbl.confirm"
                  onClick={() => mutate()}
                />
              </ButtonContainer>
            </div>
          </ResponsiveContainer>
          {window.innerWidth < 1024 && <div style={{ height: '10vh' }}></div>}
        </>
      )}
    </StyleSheetManager>
  );
};

export default FundTransfer;
