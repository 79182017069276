import Loader from 'src/components/Loader/Loader.component';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RoutePath } from 'src/routers/routers.config';
import { getStripeLoginLink } from 'src/services/stripe/stripe.config';
import { getStripeLoginLinkMethodApi } from 'src/services/stripe/stripe.request';
import { useProfileStore } from 'src/store/store';

export interface RedirectStripeProps {}

const RedirectLoginStripe: React.FC<RedirectStripeProps> = () => {
  const setUser = useProfileStore((state) => state.setUser);

  useQuery({
    queryKey: [getStripeLoginLink],
    queryFn: async () => {
      try {
        const response = await getStripeLoginLinkMethodApi();
        setUser({ stripeLoginLink: response.data.ResultSet });
        if (response.data.ResultSet.length > 1) {
          (window as any).open(response.data.ResultSet, '_self');
        }
        return response;
      } catch {
        (window as any).open(RoutePath.oops, '_self');
      }
    },
  });

  return <Loader overlayViewMode="fullscreen" active={true} viewMode="fluid" />;
};

export default RedirectLoginStripe;
