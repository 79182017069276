import { AxiosResponse } from 'axios';
import Loader from 'src/components/Loader/Loader.component';
import { useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { badRequest_400 } from 'src/services/api/api.statusCodes';
import { getPluginCheckout } from 'src/services/ecommerce/ecommerce.config';
import { getPluginCheckoutMethodApi } from 'src/services/ecommerce/ecommerce.request';
import { PluginCheckoutResponse } from 'src/services/ecommerce/ecommerce.types';
import { confirmOrderMethodApi } from 'src/services/payments/payments.request';
import { PaymentSuccessResponse } from 'src/services/payments/payments.types';

export type EcommerceContext = {
  sessionData: PluginCheckoutResponse;
  confirmOrderHandler: (values: {
    paymentMethodId: number | string;
    orderGuid: string;
    guidParam?: string;
  }) => void;
  successData?: PaymentSuccessResponse;
  isLoadingConfirmOrder: boolean;
};

function Ecommerce() {
  useEffect(() => {
    const interval = setTimeout(
      () => {
        return navigate(RoutePath.ThankyouPageError + RoutePath.expiredCart);
      },
      1000 * 60 * 30,
    );
    return () => clearInterval(interval);
  }, []);

  const [searchParams] = useSearchParams();
  const checkoutSessionGuid: string = searchParams.get('checkoutSessionGuid')!;
  const navigate = useNavigate();
  const { data: getPluginData, isLoading: getPluginLoading } = useQuery({
    queryKey: [getPluginCheckout],
    queryFn: async () => {
      try {
        const response = await getPluginCheckoutMethodApi({
          checkoutSessionGuid,
        });
        const { SameCompanyError, BuyerRegistrationStatus } =
          response.data.ResultSet;
        if (SameCompanyError && BuyerRegistrationStatus !== 'not_registered') {
          navigate(RoutePath.ThankyouPageError + RoutePath.ecommerceWrongData);
        }
        navigate(
          BuyerRegistrationStatus === 'not_registered'
            ? `${RoutePath.ecommerce}${RoutePath.ecommerceCheckData}?checkoutSessionGuid=${checkoutSessionGuid}`
            : `${RoutePath.ecommerce}${RoutePath.ecommerceCheckout}?checkoutSessionGuid=${checkoutSessionGuid}`,
        );
        return response;
      } catch (error) {
        const errorResponse = (error as any).response as AxiosResponse;
        if (errorResponse?.status === badRequest_400) {
          for (const key in errorResponse.data.errorInfos) {
            if (key === 'generic') {
              errorResponse.data.errorInfos[key] === 'order.alreadyDone'
                ? navigate(
                    RoutePath.ThankyouPageError + RoutePath.alreadyPaidPlugin,
                  )
                : navigate(RoutePath.oopsPlugin);
            }
          }
        }
      }
    },

    refetchOnMount: false,
  });
  const {
    mutate: mutateConfirmOrder,
    isPending: isLoadingConfirmOrder,
    data: dataConfirmOrder,
  } = useMutation({
    mutationFn: confirmOrderMethodApi,
    onSuccess: (res, variables) => {
      if (res.data.ResultSet.IsDeferred) {
        navigate(
          `${RoutePath.ecommerce}${RoutePath.setupSuccessPlugin}?plugin=true`,
          {
            state: { guidParam: variables.guidParam },
          },
        );
        return;
      }
      navigate(`${RoutePath.ecommerce}${RoutePath.paymentSuccessPlugin}`, {
        state: { guidParam: variables.guidParam },
      });
    },
    onError: () => navigate(RoutePath.oops),
  });

  if (getPluginLoading)
    return (
      <Loader
        backgroundColor="rgba(53, 48, 47, 0.40)"
        backdropFilter="blur(3px)"
        overlayViewMode="fullscreen"
        active
        viewMode="fluid"
      />
    );
  const sessionData = getPluginData?.data.ResultSet!;
  const ecommerceContext: EcommerceContext = {
    sessionData,
    confirmOrderHandler: (values) => {
      mutateConfirmOrder(values);
    },
    successData: dataConfirmOrder?.data.ResultSet,
    isLoadingConfirmOrder,
  };
  return <Outlet context={ecommerceContext} />;
}

export default Ecommerce;
