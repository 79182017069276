import Loader from 'src/components/Loader/Loader.component';
import { useQuery } from '@tanstack/react-query';
import { GetUrlToSignContract } from 'src/services/user/user.config';
import { GetUrlToSignContractMethodApi } from 'src/services/user/user.request';

function LoadingNamirial() {
  const { data, refetch } = useQuery({
    queryKey: [GetUrlToSignContract],
    queryFn: async () => {
      const response = await GetUrlToSignContractMethodApi();
      if (response.data.ResultSet.ReadyToSign) {
        (window as any).open(data?.data?.ResultSet.Url, '_self');
      } else {
        setTimeout(() => {
          refetch();
        }, 3000);
      }
      return response;
    },
  });

  return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
}

export default LoadingNamirial;
