import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getStatsInitialData } from 'src/services/monitoring/monitoring.config';
import {
  getStatsDataMethodApi,
  getStatsInitialDataMethodApi,
} from 'src/services/monitoring/monitoring.request';
import {
  Buyers,
  CollaboratorsStats,
} from 'src/services/monitoring/monitoring.types';
import { useProfileStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { RoleEnum } from 'src/utils/types/common.types';
import {
  DataFormMonitoringStats,
  searchMonitoringStatsFormConfig,
} from './Monitoring.helpers';
import FilteredListMonitoring from './partials/FilteredList/FilteredListMonitoring.component';
import BuyersPopover from './partials/popover/buyers/BuyersPopover.component';
import CollaboratorsPopover from './partials/popover/collaborators/CollaboratorsPopover.component';
import LinkPopover from './partials/popover/link/LinkPopover.component';
import PeriodPopover from './partials/popover/period/PeriodPopover.component';
import StatsCardWrapper from './partials/statsCardWrapper/StatsCardWrapper.component';
import TableBuyers from './partials/tableBuyers/TableBuyers.component';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Star } from 'src/images/icons/star.svg';
import { CustomInstallmentsProcessingDisclaimer } from './Monitoring.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function areEqual(
  obj1: DataFormMonitoringStats,
  obj2: DataFormMonitoringStats,
) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    const val1 = obj1[key as keyof DataFormMonitoringStats];
    const val2 = obj2[key as keyof DataFormMonitoringStats];

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (
        val1.length !== val2.length ||
        !val1.every((elem, idx) => elem === val2[idx])
      ) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

function Monitoring() {
  const Role = useProfileStore((state) => state.Role);
  const AccountType = useProfileStore((state) => state.AccountType);
  const navigate = useNavigate();
  const { resolver, initialValues } = searchMonitoringStatsFormConfig();
  const { data, isLoading } = useQuery({
    queryKey: [getStatsInitialData],
    queryFn: async () => {
      const response = await getStatsInitialDataMethodApi();
      if (Role !== RoleEnum.owner || AccountType !== 'MERCHANT') {
        navigate(RoutePath.oops);
      }
      setBuyersList(buyersInitialData);
      setCollaboratorsList(collaboratorsInitialData);
      mutate({ ...initialValues });
      return response;
    },
  });
  const {
    mutate,
    data: dataStats,
    isPending: isLoadingGetStats,
  } = useMutation({ mutationFn: getStatsDataMethodApi });
  const buyersInitialData = data?.data.ResultSet.Buyers ?? [];
  const collaboratorsInitialData = data?.data.ResultSet.Collaborators ?? [];
  const yearsInitialData = data?.data.ResultSet.Years ?? [];
  const [buyersList, setBuyersList] = useState<Buyers[] | undefined>();
  const [collaboratorsList, setCollaboratorsList] = useState<
    CollaboratorsStats[] | undefined
  >();
  const [dataFiltered, setDataFiltered] = useState(initialValues);
  const onCloseHandler = () => {
    Object.keys(dataFiltered).map((field) =>
      methods.setValue(
        field,
        dataFiltered[field as keyof DataFormMonitoringStats],
      ),
    );
  };

  const methods = useForm({ defaultValues: initialValues, resolver });
  const onSubmit = (data: any) => {
    setDataFiltered({ ...data });
    mutate({ ...data });
  };

  if (isLoading || isLoadingGetStats) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const statsData = dataStats?.data;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="bg-white">
        <ResponsiveContainer marginDesktop="0 32px">
          <Flex gap="4.8rem" flexDirection="column" padding="3.2rem 0">
            <div>
              <H4>
                <Translate id="lbl.monitoring" />
              </H4>
              <P>
                <Translate id="text.monitoringSubtitle" />
              </P>
            </div>
            <div>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="flex gap-3 overflow-auto border-0 border-b border-solid border-pblack-100 py-3 pb-3">
                    <BuyersPopover
                      submitHandler={methods.handleSubmit(onSubmit)}
                      buyersList={buyersList}
                      initialData={buyersInitialData}
                      setData={setBuyersList}
                      dataFiltered={dataFiltered}
                      onCloseHandler={onCloseHandler}
                    />
                    <PeriodPopover
                      submitHandler={methods.handleSubmit(onSubmit)}
                      initialData={yearsInitialData}
                      dataFiltered={dataFiltered}
                      onCloseHandler={onCloseHandler}
                    />
                    {data?.data.ResultSet.HasPlugin && (
                      <LinkPopover
                        submitHandler={methods.handleSubmit(onSubmit)}
                        dataFiltered={dataFiltered}
                        onCloseHandler={onCloseHandler}
                      />
                    )}
                    {collaboratorsInitialData.length > 0 && (
                      <CollaboratorsPopover
                        submitHandler={methods.handleSubmit(onSubmit)}
                        collaboratorsList={collaboratorsList}
                        initialData={collaboratorsInitialData}
                        setData={setCollaboratorsList}
                        dataFiltered={dataFiltered}
                        onCloseHandler={onCloseHandler}
                      />
                    )}
                  </div>
                </form>
                <FilteredListMonitoring
                  setDataFilter={setDataFiltered}
                  handleClick={onSubmit}
                  dataForm={dataFiltered}
                  buyers={buyersInitialData}
                  collaborators={collaboratorsInitialData}
                  initialData={initialValues}
                />
              </FormProvider>
            </div>
            <div>
              <P bold>
                <Translate id="lbl.overview" />
              </P>
              <P marginBottom="2.4rem">
                <Translate id="text.overviewDescr" />
              </P>
              <StatsCardWrapper statsData={statsData} />
            </div>
          </Flex>
        </ResponsiveContainer>
      </div>
      <ResponsiveContainer marginDesktop="0 32px">
        <div className="py-10">
          <P marginBottom="0.8rem" bold>
            <Translate id="lbl.customers" />
          </P>

          {statsData?.ResultSet?.InstallmentsProcessing
            ? statsData?.ResultSet?.InstallmentsProcessing > 0 && (
                <Flex>
                  <Icon
                    svgIconComponent={Star}
                    margin="auto 0"
                    iconSize="1.6rem"
                    iconHeight="1.6rem"
                  />
                  <CustomInstallmentsProcessingDisclaimer>
                    <Translate id="lbl.PBMonitoringDisclaimer" />
                  </CustomInstallmentsProcessingDisclaimer>
                </Flex>
              )
            : null}

          <TableBuyers
            data={statsData}
            criteria={!areEqual(initialValues, dataFiltered)}
            isLoading={isLoadingGetStats}
          />
        </div>
      </ResponsiveContainer>
    </StyleSheetManager>
  );
}

export default Monitoring;
