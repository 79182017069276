import { ReactComponent as SecondStepIcon } from 'src/images/icons/earnings.svg';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getUWBuyer } from 'src/services/UWBuyer/UWBuyer.config';
import { getUWBuyerMethodApi } from 'src/services/UWBuyer/UWBuyer.request';
import { createOrderPluginCheckoutMethodApi } from 'src/services/ecommerce/ecommerce.request';
import { useCurrentTransactionStore } from 'src/store/store';
import { Flex } from 'src/style/flexbox.style';
import { ECommerceContainer } from './ECommerceCheckout.style';
import AccessCard from './partials/AccessCard/AccessCard.component';
import ChoosePlan from './partials/ChoosePlan/ChoosePlan.component';
import CompanyDataCard from './partials/CompanyDataCard/CompanyDataCard.component';
import PaymentCard from './partials/PaymentCard/PaymentCard.component';
import StepCard from './partials/StepCard/StepCard.component';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const ECommerceCheckout = () => {
  const setCurrentTransaction = useCurrentTransactionStore(
    (state) => state.setTransactions,
  );
  const [searchParams] = useSearchParams();
  const checkoutSessionGuid: string = searchParams.get('checkoutSessionGuid')!;
  const sessionData = useOutletContext<EcommerceContext>().sessionData;
  const [polling, setPolling] = useState(false);
  const navigate = useNavigate();

  const {
    mutate: mutateCreateOrder,
    isPending: isLoadingCreateOrder,
    data: dataCreateOrder,
    isSuccess: isSuccessCreateOrder,
  } = useMutation({
    mutationFn: createOrderPluginCheckoutMethodApi,
    onSuccess: async (res) => {
      const {
        OrderGuid,
        FirstInstallmentGuid,
        FirstInstallmentAmount,
        OrderId,
      } = res.data.ResultSet;
      setCurrentTransaction({
        orderGuid: OrderGuid,
        transactionsGuid: [FirstInstallmentGuid],
        amount: FirstInstallmentAmount,
        paymentMethodId: 0,
        orderId: OrderId,
        type: 'pay',
      });
    },
    onError: () => navigate(RoutePath.oopsPlugin),
  });

  const { data: dataGetUw } = useQuery({
    queryKey: [getUWBuyer],
    queryFn: async () => {
      try {
        const response = await getUWBuyerMethodApi({
          uwId: sessionData.UwId,
        });
        const { Result, Status } = response?.data?.ResultSet ?? {};
        if (Result === 'OK' && Status === 1) {
          setPolling(false);
        } else if (Status !== 0) {
          setPolling(false);
          navigate(RoutePath.ThankyouPageError + RoutePath.uwBuyerErrorPlugin, {
            state: { plugin: true },
          });
        }
        return response;
      } catch {
        navigate(RoutePath.oopsPlugin);
      }
    },

    refetchInterval: 2000,
    enabled: polling,
  });

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ECommerceContainer>
        <CompanyDataCard companyData={sessionData} />
        <Flex gap="2.4rem" flexDirection="column" flex="1" justify="flex-start">
          <AccessCard
            startPolling={setPolling}
            getPluginData={sessionData}
            checkoutSessionGuid={checkoutSessionGuid}
          />
          <ChoosePlan
            mutateCreateOrder={mutateCreateOrder}
            getPluginData={sessionData}
            checkoutSessionGuid={checkoutSessionGuid}
            isLoadingCreate={isLoadingCreateOrder}
            resultUw={dataGetUw?.data}
          />
          <StepCard
            icon={SecondStepIcon}
            stepNumber={3}
            stepTitle="text.paymentMethod"
          >
            <PaymentCard
              isSuccessCreateOrder={isSuccessCreateOrder}
              dataCreateOrder={dataCreateOrder?.data.ResultSet}
              getPluginData={sessionData}
              isLoading={isLoadingCreateOrder}
              isLoadingUW={polling}
            />
          </StepCard>
        </Flex>
      </ECommerceContainer>
    </StyleSheetManager>
  );
};

export default ECommerceCheckout;
